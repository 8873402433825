import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1ab487da = () => interopDefault(import('../pages/condicoes-utilizacao/index.vue' /* webpackChunkName: "pages/condicoes-utilizacao/index" */))
const _58aec46e = () => interopDefault(import('../pages/contacte-nos/index.vue' /* webpackChunkName: "pages/contacte-nos/index" */))
const _1bcbdb41 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0655a74f = () => interopDefault(import('../pages/home.js' /* webpackChunkName: "pages/home" */))
const _585efaf7 = () => interopDefault(import('../pages/simulacao-seguro-multirriscos/index.vue' /* webpackChunkName: "pages/simulacao-seguro-multirriscos/index" */))
const _5121f86b = () => interopDefault(import('../pages/simulacao-seguro-saude/index.vue' /* webpackChunkName: "pages/simulacao-seguro-saude/index" */))
const _088f4a98 = () => interopDefault(import('../pages/simulacao-seguro-viagem/index.vue' /* webpackChunkName: "pages/simulacao-seguro-viagem/index" */))
const _48c3703a = () => interopDefault(import('../pages/simulacao-seguro-vida/index.vue' /* webpackChunkName: "pages/simulacao-seguro-vida/index" */))
const _5fe43c47 = () => interopDefault(import('../pages/condicoes-utilizacao/terms.js' /* webpackChunkName: "pages/condicoes-utilizacao/terms" */))
const _c8af7f5a = () => interopDefault(import('../pages/contacte-nos/contact.js' /* webpackChunkName: "pages/contacte-nos/contact" */))
const _12ee6406 = () => interopDefault(import('../pages/contacte-nos/obrigado/index.vue' /* webpackChunkName: "pages/contacte-nos/obrigado/index" */))
const _6262b6d8 = () => interopDefault(import('../pages/simulacao-seguro-automovel/simulador/index.vue' /* webpackChunkName: "pages/simulacao-seguro-automovel/simulador/index" */))
const _3ff4eb2e = () => interopDefault(import('../pages/simulacao-seguro-automovel/simulador-novo/index.vue' /* webpackChunkName: "pages/simulacao-seguro-automovel/simulador-novo/index" */))
const _22c69925 = () => interopDefault(import('../pages/simulacao-seguro-multirriscos/simulacao-seguro-multirriscos.js' /* webpackChunkName: "pages/simulacao-seguro-multirriscos/simulacao-seguro-multirriscos" */))
const _2967da5c = () => interopDefault(import('../pages/simulacao-seguro-multirriscos/simulador/index.vue' /* webpackChunkName: "pages/simulacao-seguro-multirriscos/simulador/index" */))
const _163fe85e = () => interopDefault(import('../pages/simulacao-seguro-saude/simulacao-seguro-saude.js' /* webpackChunkName: "pages/simulacao-seguro-saude/simulacao-seguro-saude" */))
const _13f396d0 = () => interopDefault(import('../pages/simulacao-seguro-saude/simulador/index.vue' /* webpackChunkName: "pages/simulacao-seguro-saude/simulador/index" */))
const _feedb52a = () => interopDefault(import('../pages/simulacao-seguro-viagem/simulacao-seguro-viagem.js' /* webpackChunkName: "pages/simulacao-seguro-viagem/simulacao-seguro-viagem" */))
const _06a31c4e = () => interopDefault(import('../pages/simulacao-seguro-viagem/simulador/index.vue' /* webpackChunkName: "pages/simulacao-seguro-viagem/simulador/index" */))
const _10a5a54d = () => interopDefault(import('../pages/simulacao-seguro-vida/simulacao-seguro-vida.js' /* webpackChunkName: "pages/simulacao-seguro-vida/simulacao-seguro-vida" */))
const _11ccf048 = () => interopDefault(import('../pages/simulacao-seguro-vida/simulador/index.vue' /* webpackChunkName: "pages/simulacao-seguro-vida/simulador/index" */))
const _ae618df8 = () => interopDefault(import('../pages/contacte-nos/obrigado/obrigado.js' /* webpackChunkName: "pages/contacte-nos/obrigado/obrigado" */))
const _3f4159e2 = () => interopDefault(import('../pages/simulacao-seguro-automovel/simulador-novo/simulation.js' /* webpackChunkName: "pages/simulacao-seguro-automovel/simulador-novo/simulation" */))
const _992d408c = () => interopDefault(import('../pages/simulacao-seguro-automovel/simulador/simulation.js' /* webpackChunkName: "pages/simulacao-seguro-automovel/simulador/simulation" */))
const _0f627182 = () => interopDefault(import('../pages/simulacao-seguro-multirriscos/simulador/simulation.js' /* webpackChunkName: "pages/simulacao-seguro-multirriscos/simulador/simulation" */))
const _d94ab146 = () => interopDefault(import('../pages/simulacao-seguro-saude/simulador/simulador.js' /* webpackChunkName: "pages/simulacao-seguro-saude/simulador/simulador" */))
const _c81bf898 = () => interopDefault(import('../pages/simulacao-seguro-viagem/simulador/simulador.js' /* webpackChunkName: "pages/simulacao-seguro-viagem/simulador/simulador" */))
const _438d3ae5 = () => interopDefault(import('../pages/simulacao-seguro-vida/simulador/simulador.js' /* webpackChunkName: "pages/simulacao-seguro-vida/simulador/simulador" */))
const _06ae6105 = () => interopDefault(import('../pages/simulacao-seguro-automovel/resultados/_id/index.vue' /* webpackChunkName: "pages/simulacao-seguro-automovel/resultados/_id/index" */))
const _497c6916 = () => interopDefault(import('../pages/simulacao-seguro-automovel/simulador-novo/_companyId/index.vue' /* webpackChunkName: "pages/simulacao-seguro-automovel/simulador-novo/_companyId/index" */))
const _5a7b386a = () => interopDefault(import('../pages/simulacao-seguro-automovel/simulador/_companyId/index.vue' /* webpackChunkName: "pages/simulacao-seguro-automovel/simulador/_companyId/index" */))
const _29d7883d = () => interopDefault(import('../pages/simulacao-seguro-multirriscos/resultados/_id/index.vue' /* webpackChunkName: "pages/simulacao-seguro-multirriscos/resultados/_id/index" */))
const _9999176e = () => interopDefault(import('../pages/simulacao-seguro-saude/resultados/_id/index.vue' /* webpackChunkName: "pages/simulacao-seguro-saude/resultados/_id/index" */))
const _1935d240 = () => interopDefault(import('../pages/simulacao-seguro-viagem/resultados/_id/index.vue' /* webpackChunkName: "pages/simulacao-seguro-viagem/resultados/_id/index" */))
const _210f45d1 = () => interopDefault(import('../pages/simulacao-seguro-vida/resultados/_id/index.vue' /* webpackChunkName: "pages/simulacao-seguro-vida/resultados/_id/index" */))
const _61185436 = () => interopDefault(import('../pages/simulacao-seguro-vida/simulador/_companyId/index.vue' /* webpackChunkName: "pages/simulacao-seguro-vida/simulador/_companyId/index" */))
const _723c2e1b = () => interopDefault(import('../pages/simulacao-seguro-automovel/simulador-novo/_companyId/simulation.js' /* webpackChunkName: "pages/simulacao-seguro-automovel/simulador-novo/_companyId/simulation" */))
const _a747cee0 = () => interopDefault(import('../pages/simulacao-seguro-automovel/simulador/_companyId/simulation.js' /* webpackChunkName: "pages/simulacao-seguro-automovel/simulador/_companyId/simulation" */))
const _79e94976 = () => interopDefault(import('../pages/simulacao-seguro-casa/resultados/_id/results.js' /* webpackChunkName: "pages/simulacao-seguro-casa/resultados/_id/results" */))
const _758c7264 = () => interopDefault(import('../pages/simulacao-seguro-multirriscos/resultados/_id/results.js' /* webpackChunkName: "pages/simulacao-seguro-multirriscos/resultados/_id/results" */))
const _675b5c42 = () => interopDefault(import('../pages/simulacao-seguro-saude/resultados/_id/results.js' /* webpackChunkName: "pages/simulacao-seguro-saude/resultados/_id/results" */))
const _2d5e0c8b = () => interopDefault(import('../pages/simulacao-seguro-viagem/resultados/_id/results.js' /* webpackChunkName: "pages/simulacao-seguro-viagem/resultados/_id/results" */))
const _960c888c = () => interopDefault(import('../pages/simulacao-seguro-vida/resultados/_id/results.js' /* webpackChunkName: "pages/simulacao-seguro-vida/resultados/_id/results" */))
const _4c61af92 = () => interopDefault(import('../pages/simulacao-seguro-vida/simulador/_companyId/simulador.js' /* webpackChunkName: "pages/simulacao-seguro-vida/simulador/_companyId/simulador" */))
const _ade989ba = () => interopDefault(import('../pages/simulacao-seguro-automovel/resultados/_id/_hash/index.vue' /* webpackChunkName: "pages/simulacao-seguro-automovel/resultados/_id/_hash/index" */))
const _aae0277e = () => interopDefault(import('../pages/simulacao-seguro-multirriscos/obrigado/_id/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-multirriscos/obrigado/_id/_plan/index" */))
const _abc75f54 = () => interopDefault(import('../pages/simulacao-seguro-multirriscos/pedir-contacto/_id/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-multirriscos/pedir-contacto/_id/_plan/index" */))
const _1f738694 = () => interopDefault(import('../pages/simulacao-seguro-multirriscos/resultados/_id/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-multirriscos/resultados/_id/_plan/index" */))
const _0cbef24d = () => interopDefault(import('../pages/simulacao-seguro-saude/obrigado/_id/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-saude/obrigado/_id/_plan/index" */))
const _4d399d62 = () => interopDefault(import('../pages/simulacao-seguro-saude/pedir-contacto/_id/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-saude/pedir-contacto/_id/_plan/index" */))
const _fa68207c = () => interopDefault(import('../pages/simulacao-seguro-saude/resultados/_id/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-saude/resultados/_id/_plan/index" */))
const _80c11d38 = () => interopDefault(import('../pages/simulacao-seguro-viagem/obrigado/_id/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-viagem/obrigado/_id/_plan/index" */))
const _502e2f39 = () => interopDefault(import('../pages/simulacao-seguro-viagem/pedir-contacto/_id/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-viagem/pedir-contacto/_id/_plan/index" */))
const _00edf5ce = () => interopDefault(import('../pages/simulacao-seguro-viagem/resultados/_id/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-viagem/resultados/_id/_plan/index" */))
const _c4728056 = () => interopDefault(import('../pages/simulacao-seguro-vida/obrigado/_id/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-vida/obrigado/_id/_plan/index" */))
const _34c324ea = () => interopDefault(import('../pages/simulacao-seguro-vida/pedir-contacto/_id/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-vida/pedir-contacto/_id/_plan/index" */))
const _a2df41e6 = () => interopDefault(import('../pages/simulacao-seguro-vida/resultados/_id/_companyId/index.vue' /* webpackChunkName: "pages/simulacao-seguro-vida/resultados/_id/_companyId/index" */))
const _1dd3096c = () => interopDefault(import('../pages/simulacao-seguro-vida/resultados/_id/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-vida/resultados/_id/_plan/index" */))
const _2c7c70a8 = () => interopDefault(import('../pages/simulacao-seguro-automovel/resultados/_id/_hash/results.js' /* webpackChunkName: "pages/simulacao-seguro-automovel/resultados/_id/_hash/results" */))
const _43a0c302 = () => interopDefault(import('../pages/simulacao-seguro-multirriscos/obrigado/_id/_plan/obrigado.js' /* webpackChunkName: "pages/simulacao-seguro-multirriscos/obrigado/_id/_plan/obrigado" */))
const _77ec316b = () => interopDefault(import('../pages/simulacao-seguro-multirriscos/pedir-contacto/_id/_plan/contact.js' /* webpackChunkName: "pages/simulacao-seguro-multirriscos/pedir-contacto/_id/_plan/contact" */))
const _9e1a90ae = () => interopDefault(import('../pages/simulacao-seguro-multirriscos/resultados/_id/_plan/details.js' /* webpackChunkName: "pages/simulacao-seguro-multirriscos/resultados/_id/_plan/details" */))
const _70b5518b = () => interopDefault(import('../pages/simulacao-seguro-saude/obrigado/_id/_plan/obrigado.js' /* webpackChunkName: "pages/simulacao-seguro-saude/obrigado/_id/_plan/obrigado" */))
const _387885df = () => interopDefault(import('../pages/simulacao-seguro-saude/pedir-contacto/_id/_plan/contact.js' /* webpackChunkName: "pages/simulacao-seguro-saude/pedir-contacto/_id/_plan/contact" */))
const _6f23661d = () => interopDefault(import('../pages/simulacao-seguro-saude/resultados/_id/_plan/details.js' /* webpackChunkName: "pages/simulacao-seguro-saude/resultados/_id/_plan/details" */))
const _251b323c = () => interopDefault(import('../pages/simulacao-seguro-viagem/obrigado/_id/_plan/obrigado.js' /* webpackChunkName: "pages/simulacao-seguro-viagem/obrigado/_id/_plan/obrigado" */))
const _d7d3a230 = () => interopDefault(import('../pages/simulacao-seguro-viagem/pedir-contacto/_id/_plan/contact.js' /* webpackChunkName: "pages/simulacao-seguro-viagem/pedir-contacto/_id/_plan/contact" */))
const _ebee08b4 = () => interopDefault(import('../pages/simulacao-seguro-viagem/resultados/_id/_plan/details.js' /* webpackChunkName: "pages/simulacao-seguro-viagem/resultados/_id/_plan/details" */))
const _420045da = () => interopDefault(import('../pages/simulacao-seguro-vida/obrigado/_id/_plan/obrigado.js' /* webpackChunkName: "pages/simulacao-seguro-vida/obrigado/_id/_plan/obrigado" */))
const _421fef57 = () => interopDefault(import('../pages/simulacao-seguro-vida/pedir-contacto/_id/_plan/contact.js' /* webpackChunkName: "pages/simulacao-seguro-vida/pedir-contacto/_id/_plan/contact" */))
const _50c86c04 = () => interopDefault(import('../pages/simulacao-seguro-vida/resultados/_id/_companyId/results.js' /* webpackChunkName: "pages/simulacao-seguro-vida/resultados/_id/_companyId/results" */))
const _6bab68d6 = () => interopDefault(import('../pages/simulacao-seguro-vida/resultados/_id/_plan/details.js' /* webpackChunkName: "pages/simulacao-seguro-vida/resultados/_id/_plan/details" */))
const _3776d867 = () => interopDefault(import('../pages/simulacao-seguro-automovel/obrigado/_id/_hash/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-automovel/obrigado/_id/_hash/_plan/index" */))
const _4bda3a88 = () => interopDefault(import('../pages/simulacao-seguro-automovel/pedir-contacto/_id/_hash/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-automovel/pedir-contacto/_id/_hash/_plan/index" */))
const _92367312 = () => interopDefault(import('../pages/simulacao-seguro-automovel/resultados/_id/_companyId/_hash/index.vue' /* webpackChunkName: "pages/simulacao-seguro-automovel/resultados/_id/_companyId/_hash/index" */))
const _cd74a9c8 = () => interopDefault(import('../pages/simulacao-seguro-automovel/resultados/_id/_hash/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-automovel/resultados/_id/_hash/_plan/index" */))
const _7b96b786 = () => interopDefault(import('../pages/simulacao-seguro-vida/resultados/_id/_companyId/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-vida/resultados/_id/_companyId/_plan/index" */))
const _65e7cdb6 = () => interopDefault(import('../pages/simulacao-seguro-automovel/obrigado/_id/_hash/_plan/obrigado.js' /* webpackChunkName: "pages/simulacao-seguro-automovel/obrigado/_id/_hash/_plan/obrigado" */))
const _46c7ebc5 = () => interopDefault(import('../pages/simulacao-seguro-automovel/pedir-contacto/_id/_hash/_plan/contact.js' /* webpackChunkName: "pages/simulacao-seguro-automovel/pedir-contacto/_id/_hash/_plan/contact" */))
const _4c576058 = () => interopDefault(import('../pages/simulacao-seguro-automovel/resultados/_id/_companyId/_hash/results.js' /* webpackChunkName: "pages/simulacao-seguro-automovel/resultados/_id/_companyId/_hash/results" */))
const _27e11603 = () => interopDefault(import('../pages/simulacao-seguro-automovel/resultados/_id/_hash/_plan/details.js' /* webpackChunkName: "pages/simulacao-seguro-automovel/resultados/_id/_hash/_plan/details" */))
const _e09cd24e = () => interopDefault(import('../pages/simulacao-seguro-vida/resultados/_id/_companyId/_plan/details.js' /* webpackChunkName: "pages/simulacao-seguro-vida/resultados/_id/_companyId/_plan/details" */))
const _7e166770 = () => interopDefault(import('../pages/simulacao-seguro-automovel/resultados/_id/_companyId/_hash/_plan/index.vue' /* webpackChunkName: "pages/simulacao-seguro-automovel/resultados/_id/_companyId/_hash/_plan/index" */))
const _45b037a2 = () => interopDefault(import('../pages/simulacao-seguro-automovel/resultados/_id/_companyId/_hash/_plan/details.js' /* webpackChunkName: "pages/simulacao-seguro-automovel/resultados/_id/_companyId/_hash/_plan/details" */))
const _61b12ce6 = () => interopDefault(import('../pages/detalhes-companhia/_companyName/_companyId/_type/index.vue' /* webpackChunkName: "pages/detalhes-companhia/_companyName/_companyId/_type/index" */))
const _710aa86b = () => interopDefault(import('../pages/detalhes-companhia/_companyName/_companyId/_type/detalhes-companhia.js' /* webpackChunkName: "pages/detalhes-companhia/_companyName/_companyId/_type/detalhes-companhia" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/condicoes-utilizacao",
    component: _1ab487da,
    name: "condicoes-utilizacao"
  }, {
    path: "/contacte-nos",
    component: _58aec46e,
    name: "contacte-nos"
  }, {
    path: "/en",
    component: _1bcbdb41,
    name: "index___en"
  }, {
    path: "/home",
    component: _0655a74f,
    name: "home"
  }, {
    path: "/pt",
    component: _1bcbdb41,
    name: "index___pt"
  }, {
    path: "/simulacao-seguro-multirriscos",
    component: _585efaf7,
    name: "simulacao-seguro-multirriscos"
  }, {
    path: "/simulacao-seguro-saude",
    component: _5121f86b,
    name: "simulacao-seguro-saude"
  }, {
    path: "/simulacao-seguro-viagem",
    component: _088f4a98,
    name: "simulacao-seguro-viagem"
  }, {
    path: "/simulacao-seguro-vida",
    component: _48c3703a,
    name: "simulacao-seguro-vida"
  }, {
    path: "/condicoes-utilizacao/terms",
    component: _5fe43c47,
    name: "condicoes-utilizacao-terms"
  }, {
    path: "/contacte-nos/contact",
    component: _c8af7f5a,
    name: "contacte-nos-contact"
  }, {
    path: "/contacte-nos/obrigado",
    component: _12ee6406,
    name: "contacte-nos-obrigado"
  }, {
    path: "/en/condicoes-utilizacao",
    component: _1ab487da,
    name: "condicoes-utilizacao___en"
  }, {
    path: "/en/contacte-nos",
    component: _58aec46e,
    name: "contacte-nos___en"
  }, {
    path: "/en/home",
    component: _0655a74f,
    name: "home___en"
  }, {
    path: "/en/simulacao-seguro-multirriscos",
    component: _585efaf7,
    name: "simulacao-seguro-multirriscos___en"
  }, {
    path: "/en/simulacao-seguro-saude",
    component: _5121f86b,
    name: "simulacao-seguro-saude___en"
  }, {
    path: "/en/simulacao-seguro-viagem",
    component: _088f4a98,
    name: "simulacao-seguro-viagem___en"
  }, {
    path: "/en/simulacao-seguro-vida",
    component: _48c3703a,
    name: "simulacao-seguro-vida___en"
  }, {
    path: "/pt/condicoes-utilizacao",
    component: _1ab487da,
    name: "condicoes-utilizacao___pt"
  }, {
    path: "/pt/contacte-nos",
    component: _58aec46e,
    name: "contacte-nos___pt"
  }, {
    path: "/pt/home",
    component: _0655a74f,
    name: "home___pt"
  }, {
    path: "/pt/simulacao-seguro-multirriscos",
    component: _585efaf7,
    name: "simulacao-seguro-multirriscos___pt"
  }, {
    path: "/pt/simulacao-seguro-saude",
    component: _5121f86b,
    name: "simulacao-seguro-saude___pt"
  }, {
    path: "/pt/simulacao-seguro-viagem",
    component: _088f4a98,
    name: "simulacao-seguro-viagem___pt"
  }, {
    path: "/pt/simulacao-seguro-vida",
    component: _48c3703a,
    name: "simulacao-seguro-vida___pt"
  }, {
    path: "/simulacao-seguro-automovel/simulador",
    component: _6262b6d8,
    name: "simulacao-seguro-automovel-simulador"
  }, {
    path: "/simulacao-seguro-automovel/simulador-novo",
    component: _3ff4eb2e,
    name: "simulacao-seguro-automovel-simulador-novo"
  }, {
    path: "/simulacao-seguro-multirriscos/simulacao-seguro-multirriscos",
    component: _22c69925,
    name: "simulacao-seguro-multirriscos-simulacao-seguro-multirriscos"
  }, {
    path: "/simulacao-seguro-multirriscos/simulador",
    component: _2967da5c,
    name: "simulacao-seguro-multirriscos-simulador"
  }, {
    path: "/simulacao-seguro-saude/simulacao-seguro-saude",
    component: _163fe85e,
    name: "simulacao-seguro-saude-simulacao-seguro-saude"
  }, {
    path: "/simulacao-seguro-saude/simulador",
    component: _13f396d0,
    name: "simulacao-seguro-saude-simulador"
  }, {
    path: "/simulacao-seguro-viagem/simulacao-seguro-viagem",
    component: _feedb52a,
    name: "simulacao-seguro-viagem-simulacao-seguro-viagem"
  }, {
    path: "/simulacao-seguro-viagem/simulador",
    component: _06a31c4e,
    name: "simulacao-seguro-viagem-simulador"
  }, {
    path: "/simulacao-seguro-vida/simulacao-seguro-vida",
    component: _10a5a54d,
    name: "simulacao-seguro-vida-simulacao-seguro-vida"
  }, {
    path: "/simulacao-seguro-vida/simulador",
    component: _11ccf048,
    name: "simulacao-seguro-vida-simulador"
  }, {
    path: "/contacte-nos/obrigado/obrigado",
    component: _ae618df8,
    name: "contacte-nos-obrigado-obrigado"
  }, {
    path: "/en/condicoes-utilizacao/terms",
    component: _5fe43c47,
    name: "condicoes-utilizacao-terms___en"
  }, {
    path: "/en/contacte-nos/contact",
    component: _c8af7f5a,
    name: "contacte-nos-contact___en"
  }, {
    path: "/en/contacte-nos/obrigado",
    component: _12ee6406,
    name: "contacte-nos-obrigado___en"
  }, {
    path: "/en/simulacao-seguro-automovel/simulador",
    component: _6262b6d8,
    name: "simulacao-seguro-automovel-simulador___en"
  }, {
    path: "/en/simulacao-seguro-automovel/simulador-novo",
    component: _3ff4eb2e,
    name: "simulacao-seguro-automovel-simulador-novo___en"
  }, {
    path: "/en/simulacao-seguro-multirriscos/simulacao-seguro-multirriscos",
    component: _22c69925,
    name: "simulacao-seguro-multirriscos-simulacao-seguro-multirriscos___en"
  }, {
    path: "/en/simulacao-seguro-multirriscos/simulador",
    component: _2967da5c,
    name: "simulacao-seguro-multirriscos-simulador___en"
  }, {
    path: "/en/simulacao-seguro-saude/simulacao-seguro-saude",
    component: _163fe85e,
    name: "simulacao-seguro-saude-simulacao-seguro-saude___en"
  }, {
    path: "/en/simulacao-seguro-saude/simulador",
    component: _13f396d0,
    name: "simulacao-seguro-saude-simulador___en"
  }, {
    path: "/en/simulacao-seguro-viagem/simulacao-seguro-viagem",
    component: _feedb52a,
    name: "simulacao-seguro-viagem-simulacao-seguro-viagem___en"
  }, {
    path: "/en/simulacao-seguro-viagem/simulador",
    component: _06a31c4e,
    name: "simulacao-seguro-viagem-simulador___en"
  }, {
    path: "/en/simulacao-seguro-vida/simulacao-seguro-vida",
    component: _10a5a54d,
    name: "simulacao-seguro-vida-simulacao-seguro-vida___en"
  }, {
    path: "/en/simulacao-seguro-vida/simulador",
    component: _11ccf048,
    name: "simulacao-seguro-vida-simulador___en"
  }, {
    path: "/pt/condicoes-utilizacao/terms",
    component: _5fe43c47,
    name: "condicoes-utilizacao-terms___pt"
  }, {
    path: "/pt/contacte-nos/contact",
    component: _c8af7f5a,
    name: "contacte-nos-contact___pt"
  }, {
    path: "/pt/contacte-nos/obrigado",
    component: _12ee6406,
    name: "contacte-nos-obrigado___pt"
  }, {
    path: "/pt/simulacao-seguro-automovel/simulador",
    component: _6262b6d8,
    name: "simulacao-seguro-automovel-simulador___pt"
  }, {
    path: "/pt/simulacao-seguro-automovel/simulador-novo",
    component: _3ff4eb2e,
    name: "simulacao-seguro-automovel-simulador-novo___pt"
  }, {
    path: "/pt/simulacao-seguro-multirriscos/simulacao-seguro-multirriscos",
    component: _22c69925,
    name: "simulacao-seguro-multirriscos-simulacao-seguro-multirriscos___pt"
  }, {
    path: "/pt/simulacao-seguro-multirriscos/simulador",
    component: _2967da5c,
    name: "simulacao-seguro-multirriscos-simulador___pt"
  }, {
    path: "/pt/simulacao-seguro-saude/simulacao-seguro-saude",
    component: _163fe85e,
    name: "simulacao-seguro-saude-simulacao-seguro-saude___pt"
  }, {
    path: "/pt/simulacao-seguro-saude/simulador",
    component: _13f396d0,
    name: "simulacao-seguro-saude-simulador___pt"
  }, {
    path: "/pt/simulacao-seguro-viagem/simulacao-seguro-viagem",
    component: _feedb52a,
    name: "simulacao-seguro-viagem-simulacao-seguro-viagem___pt"
  }, {
    path: "/pt/simulacao-seguro-viagem/simulador",
    component: _06a31c4e,
    name: "simulacao-seguro-viagem-simulador___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/simulacao-seguro-vida",
    component: _10a5a54d,
    name: "simulacao-seguro-vida-simulacao-seguro-vida___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/simulador",
    component: _11ccf048,
    name: "simulacao-seguro-vida-simulador___pt"
  }, {
    path: "/simulacao-seguro-automovel/simulador-novo/simulation",
    component: _3f4159e2,
    name: "simulacao-seguro-automovel-simulador-novo-simulation"
  }, {
    path: "/simulacao-seguro-automovel/simulador/simulation",
    component: _992d408c,
    name: "simulacao-seguro-automovel-simulador-simulation"
  }, {
    path: "/simulacao-seguro-multirriscos/simulador/simulation",
    component: _0f627182,
    name: "simulacao-seguro-multirriscos-simulador-simulation"
  }, {
    path: "/simulacao-seguro-saude/simulador/simulador",
    component: _d94ab146,
    name: "simulacao-seguro-saude-simulador-simulador"
  }, {
    path: "/simulacao-seguro-viagem/simulador/simulador",
    component: _c81bf898,
    name: "simulacao-seguro-viagem-simulador-simulador"
  }, {
    path: "/simulacao-seguro-vida/simulador/simulador",
    component: _438d3ae5,
    name: "simulacao-seguro-vida-simulador-simulador"
  }, {
    path: "/en/contacte-nos/obrigado/obrigado",
    component: _ae618df8,
    name: "contacte-nos-obrigado-obrigado___en"
  }, {
    path: "/en/simulacao-seguro-automovel/simulador-novo/simulation",
    component: _3f4159e2,
    name: "simulacao-seguro-automovel-simulador-novo-simulation___en"
  }, {
    path: "/en/simulacao-seguro-automovel/simulador/simulation",
    component: _992d408c,
    name: "simulacao-seguro-automovel-simulador-simulation___en"
  }, {
    path: "/en/simulacao-seguro-multirriscos/simulador/simulation",
    component: _0f627182,
    name: "simulacao-seguro-multirriscos-simulador-simulation___en"
  }, {
    path: "/en/simulacao-seguro-saude/simulador/simulador",
    component: _d94ab146,
    name: "simulacao-seguro-saude-simulador-simulador___en"
  }, {
    path: "/en/simulacao-seguro-viagem/simulador/simulador",
    component: _c81bf898,
    name: "simulacao-seguro-viagem-simulador-simulador___en"
  }, {
    path: "/en/simulacao-seguro-vida/simulador/simulador",
    component: _438d3ae5,
    name: "simulacao-seguro-vida-simulador-simulador___en"
  }, {
    path: "/pt/contacte-nos/obrigado/obrigado",
    component: _ae618df8,
    name: "contacte-nos-obrigado-obrigado___pt"
  }, {
    path: "/pt/simulacao-seguro-automovel/simulador-novo/simulation",
    component: _3f4159e2,
    name: "simulacao-seguro-automovel-simulador-novo-simulation___pt"
  }, {
    path: "/pt/simulacao-seguro-automovel/simulador/simulation",
    component: _992d408c,
    name: "simulacao-seguro-automovel-simulador-simulation___pt"
  }, {
    path: "/pt/simulacao-seguro-multirriscos/simulador/simulation",
    component: _0f627182,
    name: "simulacao-seguro-multirriscos-simulador-simulation___pt"
  }, {
    path: "/pt/simulacao-seguro-saude/simulador/simulador",
    component: _d94ab146,
    name: "simulacao-seguro-saude-simulador-simulador___pt"
  }, {
    path: "/pt/simulacao-seguro-viagem/simulador/simulador",
    component: _c81bf898,
    name: "simulacao-seguro-viagem-simulador-simulador___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/simulador/simulador",
    component: _438d3ae5,
    name: "simulacao-seguro-vida-simulador-simulador___pt"
  }, {
    path: "/en/simulacao-seguro-automovel/resultados/:id",
    component: _06ae6105,
    name: "simulacao-seguro-automovel-resultados-id___en"
  }, {
    path: "/en/simulacao-seguro-automovel/simulador-novo/:companyId",
    component: _497c6916,
    name: "simulacao-seguro-automovel-simulador-novo-companyId___en"
  }, {
    path: "/en/simulacao-seguro-automovel/simulador/:companyId",
    component: _5a7b386a,
    name: "simulacao-seguro-automovel-simulador-companyId___en"
  }, {
    path: "/en/simulacao-seguro-multirriscos/resultados/:id",
    component: _29d7883d,
    name: "simulacao-seguro-multirriscos-resultados-id___en"
  }, {
    path: "/en/simulacao-seguro-saude/resultados/:id",
    component: _9999176e,
    name: "simulacao-seguro-saude-resultados-id___en"
  }, {
    path: "/en/simulacao-seguro-viagem/resultados/:id",
    component: _1935d240,
    name: "simulacao-seguro-viagem-resultados-id___en"
  }, {
    path: "/en/simulacao-seguro-vida/resultados/:id",
    component: _210f45d1,
    name: "simulacao-seguro-vida-resultados-id___en"
  }, {
    path: "/en/simulacao-seguro-vida/simulador/:companyId",
    component: _61185436,
    name: "simulacao-seguro-vida-simulador-companyId___en"
  }, {
    path: "/pt/simulacao-seguro-automovel/resultados/:id",
    component: _06ae6105,
    name: "simulacao-seguro-automovel-resultados-id___pt"
  }, {
    path: "/pt/simulacao-seguro-automovel/simulador-novo/:companyId",
    component: _497c6916,
    name: "simulacao-seguro-automovel-simulador-novo-companyId___pt"
  }, {
    path: "/pt/simulacao-seguro-automovel/simulador/:companyId",
    component: _5a7b386a,
    name: "simulacao-seguro-automovel-simulador-companyId___pt"
  }, {
    path: "/pt/simulacao-seguro-multirriscos/resultados/:id",
    component: _29d7883d,
    name: "simulacao-seguro-multirriscos-resultados-id___pt"
  }, {
    path: "/pt/simulacao-seguro-saude/resultados/:id",
    component: _9999176e,
    name: "simulacao-seguro-saude-resultados-id___pt"
  }, {
    path: "/pt/simulacao-seguro-viagem/resultados/:id",
    component: _1935d240,
    name: "simulacao-seguro-viagem-resultados-id___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/resultados/:id",
    component: _210f45d1,
    name: "simulacao-seguro-vida-resultados-id___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/simulador/:companyId",
    component: _61185436,
    name: "simulacao-seguro-vida-simulador-companyId___pt"
  }, {
    path: "/en/simulacao-seguro-automovel/simulador-novo/:companyId?/simulation",
    component: _723c2e1b,
    name: "simulacao-seguro-automovel-simulador-novo-companyId-simulation___en"
  }, {
    path: "/en/simulacao-seguro-automovel/simulador/:companyId?/simulation",
    component: _a747cee0,
    name: "simulacao-seguro-automovel-simulador-companyId-simulation___en"
  }, {
    path: "/en/simulacao-seguro-casa/resultados/:id?/results",
    component: _79e94976,
    name: "simulacao-seguro-casa-resultados-id-results___en"
  }, {
    path: "/en/simulacao-seguro-multirriscos/resultados/:id?/results",
    component: _758c7264,
    name: "simulacao-seguro-multirriscos-resultados-id-results___en"
  }, {
    path: "/en/simulacao-seguro-saude/resultados/:id?/results",
    component: _675b5c42,
    name: "simulacao-seguro-saude-resultados-id-results___en"
  }, {
    path: "/en/simulacao-seguro-viagem/resultados/:id?/results",
    component: _2d5e0c8b,
    name: "simulacao-seguro-viagem-resultados-id-results___en"
  }, {
    path: "/en/simulacao-seguro-vida/resultados/:id?/results",
    component: _960c888c,
    name: "simulacao-seguro-vida-resultados-id-results___en"
  }, {
    path: "/en/simulacao-seguro-vida/simulador/:companyId?/simulador",
    component: _4c61af92,
    name: "simulacao-seguro-vida-simulador-companyId-simulador___en"
  }, {
    path: "/pt/simulacao-seguro-automovel/simulador-novo/:companyId?/simulation",
    component: _723c2e1b,
    name: "simulacao-seguro-automovel-simulador-novo-companyId-simulation___pt"
  }, {
    path: "/pt/simulacao-seguro-automovel/simulador/:companyId?/simulation",
    component: _a747cee0,
    name: "simulacao-seguro-automovel-simulador-companyId-simulation___pt"
  }, {
    path: "/pt/simulacao-seguro-casa/resultados/:id?/results",
    component: _79e94976,
    name: "simulacao-seguro-casa-resultados-id-results___pt"
  }, {
    path: "/pt/simulacao-seguro-multirriscos/resultados/:id?/results",
    component: _758c7264,
    name: "simulacao-seguro-multirriscos-resultados-id-results___pt"
  }, {
    path: "/pt/simulacao-seguro-saude/resultados/:id?/results",
    component: _675b5c42,
    name: "simulacao-seguro-saude-resultados-id-results___pt"
  }, {
    path: "/pt/simulacao-seguro-viagem/resultados/:id?/results",
    component: _2d5e0c8b,
    name: "simulacao-seguro-viagem-resultados-id-results___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/resultados/:id?/results",
    component: _960c888c,
    name: "simulacao-seguro-vida-resultados-id-results___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/simulador/:companyId?/simulador",
    component: _4c61af92,
    name: "simulacao-seguro-vida-simulador-companyId-simulador___pt"
  }, {
    path: "/en/simulacao-seguro-automovel/resultados/:id?/:hash",
    component: _ade989ba,
    name: "simulacao-seguro-automovel-resultados-id-hash___en"
  }, {
    path: "/en/simulacao-seguro-multirriscos/obrigado/:id?/:plan",
    component: _aae0277e,
    name: "simulacao-seguro-multirriscos-obrigado-id-plan___en"
  }, {
    path: "/en/simulacao-seguro-multirriscos/pedir-contacto/:id?/:plan",
    component: _abc75f54,
    name: "simulacao-seguro-multirriscos-pedir-contacto-id-plan___en"
  }, {
    path: "/en/simulacao-seguro-multirriscos/resultados/:id?/:plan",
    component: _1f738694,
    name: "simulacao-seguro-multirriscos-resultados-id-plan___en"
  }, {
    path: "/en/simulacao-seguro-saude/obrigado/:id?/:plan",
    component: _0cbef24d,
    name: "simulacao-seguro-saude-obrigado-id-plan___en"
  }, {
    path: "/en/simulacao-seguro-saude/pedir-contacto/:id?/:plan",
    component: _4d399d62,
    name: "simulacao-seguro-saude-pedir-contacto-id-plan___en"
  }, {
    path: "/en/simulacao-seguro-saude/resultados/:id?/:plan",
    component: _fa68207c,
    name: "simulacao-seguro-saude-resultados-id-plan___en"
  }, {
    path: "/en/simulacao-seguro-viagem/obrigado/:id?/:plan",
    component: _80c11d38,
    name: "simulacao-seguro-viagem-obrigado-id-plan___en"
  }, {
    path: "/en/simulacao-seguro-viagem/pedir-contacto/:id?/:plan",
    component: _502e2f39,
    name: "simulacao-seguro-viagem-pedir-contacto-id-plan___en"
  }, {
    path: "/en/simulacao-seguro-viagem/resultados/:id?/:plan",
    component: _00edf5ce,
    name: "simulacao-seguro-viagem-resultados-id-plan___en"
  }, {
    path: "/en/simulacao-seguro-vida/obrigado/:id?/:plan",
    component: _c4728056,
    name: "simulacao-seguro-vida-obrigado-id-plan___en"
  }, {
    path: "/en/simulacao-seguro-vida/pedir-contacto/:id?/:plan",
    component: _34c324ea,
    name: "simulacao-seguro-vida-pedir-contacto-id-plan___en"
  }, {
    path: "/en/simulacao-seguro-vida/resultados/:id?/:companyId",
    component: _a2df41e6,
    name: "simulacao-seguro-vida-resultados-id-companyId___en"
  }, {
    path: "/en/simulacao-seguro-vida/resultados/:id?/:plan",
    component: _1dd3096c,
    name: "simulacao-seguro-vida-resultados-id-plan___en"
  }, {
    path: "/pt/simulacao-seguro-automovel/resultados/:id?/:hash",
    component: _ade989ba,
    name: "simulacao-seguro-automovel-resultados-id-hash___pt"
  }, {
    path: "/pt/simulacao-seguro-multirriscos/obrigado/:id?/:plan",
    component: _aae0277e,
    name: "simulacao-seguro-multirriscos-obrigado-id-plan___pt"
  }, {
    path: "/pt/simulacao-seguro-multirriscos/pedir-contacto/:id?/:plan",
    component: _abc75f54,
    name: "simulacao-seguro-multirriscos-pedir-contacto-id-plan___pt"
  }, {
    path: "/pt/simulacao-seguro-multirriscos/resultados/:id?/:plan",
    component: _1f738694,
    name: "simulacao-seguro-multirriscos-resultados-id-plan___pt"
  }, {
    path: "/pt/simulacao-seguro-saude/obrigado/:id?/:plan",
    component: _0cbef24d,
    name: "simulacao-seguro-saude-obrigado-id-plan___pt"
  }, {
    path: "/pt/simulacao-seguro-saude/pedir-contacto/:id?/:plan",
    component: _4d399d62,
    name: "simulacao-seguro-saude-pedir-contacto-id-plan___pt"
  }, {
    path: "/pt/simulacao-seguro-saude/resultados/:id?/:plan",
    component: _fa68207c,
    name: "simulacao-seguro-saude-resultados-id-plan___pt"
  }, {
    path: "/pt/simulacao-seguro-viagem/obrigado/:id?/:plan",
    component: _80c11d38,
    name: "simulacao-seguro-viagem-obrigado-id-plan___pt"
  }, {
    path: "/pt/simulacao-seguro-viagem/pedir-contacto/:id?/:plan",
    component: _502e2f39,
    name: "simulacao-seguro-viagem-pedir-contacto-id-plan___pt"
  }, {
    path: "/pt/simulacao-seguro-viagem/resultados/:id?/:plan",
    component: _00edf5ce,
    name: "simulacao-seguro-viagem-resultados-id-plan___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/obrigado/:id?/:plan",
    component: _c4728056,
    name: "simulacao-seguro-vida-obrigado-id-plan___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/pedir-contacto/:id?/:plan",
    component: _34c324ea,
    name: "simulacao-seguro-vida-pedir-contacto-id-plan___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/resultados/:id?/:companyId",
    component: _a2df41e6,
    name: "simulacao-seguro-vida-resultados-id-companyId___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/resultados/:id?/:plan",
    component: _1dd3096c,
    name: "simulacao-seguro-vida-resultados-id-plan___pt"
  }, {
    path: "/en/simulacao-seguro-automovel/resultados/:id?/:hash?/results",
    component: _2c7c70a8,
    name: "simulacao-seguro-automovel-resultados-id-hash-results___en"
  }, {
    path: "/en/simulacao-seguro-multirriscos/obrigado/:id?/:plan/obrigado",
    component: _43a0c302,
    name: "simulacao-seguro-multirriscos-obrigado-id-plan-obrigado___en"
  }, {
    path: "/en/simulacao-seguro-multirriscos/pedir-contacto/:id?/:plan/contact",
    component: _77ec316b,
    name: "simulacao-seguro-multirriscos-pedir-contacto-id-plan-contact___en"
  }, {
    path: "/en/simulacao-seguro-multirriscos/resultados/:id?/:plan/details",
    component: _9e1a90ae,
    name: "simulacao-seguro-multirriscos-resultados-id-plan-details___en"
  }, {
    path: "/en/simulacao-seguro-saude/obrigado/:id?/:plan/obrigado",
    component: _70b5518b,
    name: "simulacao-seguro-saude-obrigado-id-plan-obrigado___en"
  }, {
    path: "/en/simulacao-seguro-saude/pedir-contacto/:id?/:plan/contact",
    component: _387885df,
    name: "simulacao-seguro-saude-pedir-contacto-id-plan-contact___en"
  }, {
    path: "/en/simulacao-seguro-saude/resultados/:id?/:plan/details",
    component: _6f23661d,
    name: "simulacao-seguro-saude-resultados-id-plan-details___en"
  }, {
    path: "/en/simulacao-seguro-viagem/obrigado/:id?/:plan/obrigado",
    component: _251b323c,
    name: "simulacao-seguro-viagem-obrigado-id-plan-obrigado___en"
  }, {
    path: "/en/simulacao-seguro-viagem/pedir-contacto/:id?/:plan/contact",
    component: _d7d3a230,
    name: "simulacao-seguro-viagem-pedir-contacto-id-plan-contact___en"
  }, {
    path: "/en/simulacao-seguro-viagem/resultados/:id?/:plan/details",
    component: _ebee08b4,
    name: "simulacao-seguro-viagem-resultados-id-plan-details___en"
  }, {
    path: "/en/simulacao-seguro-vida/obrigado/:id?/:plan/obrigado",
    component: _420045da,
    name: "simulacao-seguro-vida-obrigado-id-plan-obrigado___en"
  }, {
    path: "/en/simulacao-seguro-vida/pedir-contacto/:id?/:plan/contact",
    component: _421fef57,
    name: "simulacao-seguro-vida-pedir-contacto-id-plan-contact___en"
  }, {
    path: "/en/simulacao-seguro-vida/resultados/:id?/:companyId/results",
    component: _50c86c04,
    name: "simulacao-seguro-vida-resultados-id-companyId-results___en"
  }, {
    path: "/en/simulacao-seguro-vida/resultados/:id?/:plan/details",
    component: _6bab68d6,
    name: "simulacao-seguro-vida-resultados-id-plan-details___en"
  }, {
    path: "/pt/simulacao-seguro-automovel/resultados/:id?/:hash?/results",
    component: _2c7c70a8,
    name: "simulacao-seguro-automovel-resultados-id-hash-results___pt"
  }, {
    path: "/pt/simulacao-seguro-multirriscos/obrigado/:id?/:plan/obrigado",
    component: _43a0c302,
    name: "simulacao-seguro-multirriscos-obrigado-id-plan-obrigado___pt"
  }, {
    path: "/pt/simulacao-seguro-multirriscos/pedir-contacto/:id?/:plan/contact",
    component: _77ec316b,
    name: "simulacao-seguro-multirriscos-pedir-contacto-id-plan-contact___pt"
  }, {
    path: "/pt/simulacao-seguro-multirriscos/resultados/:id?/:plan/details",
    component: _9e1a90ae,
    name: "simulacao-seguro-multirriscos-resultados-id-plan-details___pt"
  }, {
    path: "/pt/simulacao-seguro-saude/obrigado/:id?/:plan/obrigado",
    component: _70b5518b,
    name: "simulacao-seguro-saude-obrigado-id-plan-obrigado___pt"
  }, {
    path: "/pt/simulacao-seguro-saude/pedir-contacto/:id?/:plan/contact",
    component: _387885df,
    name: "simulacao-seguro-saude-pedir-contacto-id-plan-contact___pt"
  }, {
    path: "/pt/simulacao-seguro-saude/resultados/:id?/:plan/details",
    component: _6f23661d,
    name: "simulacao-seguro-saude-resultados-id-plan-details___pt"
  }, {
    path: "/pt/simulacao-seguro-viagem/obrigado/:id?/:plan/obrigado",
    component: _251b323c,
    name: "simulacao-seguro-viagem-obrigado-id-plan-obrigado___pt"
  }, {
    path: "/pt/simulacao-seguro-viagem/pedir-contacto/:id?/:plan/contact",
    component: _d7d3a230,
    name: "simulacao-seguro-viagem-pedir-contacto-id-plan-contact___pt"
  }, {
    path: "/pt/simulacao-seguro-viagem/resultados/:id?/:plan/details",
    component: _ebee08b4,
    name: "simulacao-seguro-viagem-resultados-id-plan-details___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/obrigado/:id?/:plan/obrigado",
    component: _420045da,
    name: "simulacao-seguro-vida-obrigado-id-plan-obrigado___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/pedir-contacto/:id?/:plan/contact",
    component: _421fef57,
    name: "simulacao-seguro-vida-pedir-contacto-id-plan-contact___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/resultados/:id?/:companyId/results",
    component: _50c86c04,
    name: "simulacao-seguro-vida-resultados-id-companyId-results___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/resultados/:id?/:plan/details",
    component: _6bab68d6,
    name: "simulacao-seguro-vida-resultados-id-plan-details___pt"
  }, {
    path: "/en/simulacao-seguro-automovel/obrigado/:id?/:hash?/:plan",
    component: _3776d867,
    name: "simulacao-seguro-automovel-obrigado-id-hash-plan___en"
  }, {
    path: "/en/simulacao-seguro-automovel/pedir-contacto/:id?/:hash?/:plan",
    component: _4bda3a88,
    name: "simulacao-seguro-automovel-pedir-contacto-id-hash-plan___en"
  }, {
    path: "/en/simulacao-seguro-automovel/resultados/:id?/:companyId?/:hash",
    component: _92367312,
    name: "simulacao-seguro-automovel-resultados-id-companyId-hash___en"
  }, {
    path: "/en/simulacao-seguro-automovel/resultados/:id?/:hash?/:plan",
    component: _cd74a9c8,
    name: "simulacao-seguro-automovel-resultados-id-hash-plan___en"
  }, {
    path: "/en/simulacao-seguro-vida/resultados/:id?/:companyId/:plan",
    component: _7b96b786,
    name: "simulacao-seguro-vida-resultados-id-companyId-plan___en"
  }, {
    path: "/pt/simulacao-seguro-automovel/obrigado/:id?/:hash?/:plan",
    component: _3776d867,
    name: "simulacao-seguro-automovel-obrigado-id-hash-plan___pt"
  }, {
    path: "/pt/simulacao-seguro-automovel/pedir-contacto/:id?/:hash?/:plan",
    component: _4bda3a88,
    name: "simulacao-seguro-automovel-pedir-contacto-id-hash-plan___pt"
  }, {
    path: "/pt/simulacao-seguro-automovel/resultados/:id?/:companyId?/:hash",
    component: _92367312,
    name: "simulacao-seguro-automovel-resultados-id-companyId-hash___pt"
  }, {
    path: "/pt/simulacao-seguro-automovel/resultados/:id?/:hash?/:plan",
    component: _cd74a9c8,
    name: "simulacao-seguro-automovel-resultados-id-hash-plan___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/resultados/:id?/:companyId/:plan",
    component: _7b96b786,
    name: "simulacao-seguro-vida-resultados-id-companyId-plan___pt"
  }, {
    path: "/en/simulacao-seguro-automovel/obrigado/:id?/:hash?/:plan?/obrigado",
    component: _65e7cdb6,
    name: "simulacao-seguro-automovel-obrigado-id-hash-plan-obrigado___en"
  }, {
    path: "/en/simulacao-seguro-automovel/pedir-contacto/:id?/:hash?/:plan?/contact",
    component: _46c7ebc5,
    name: "simulacao-seguro-automovel-pedir-contacto-id-hash-plan-contact___en"
  }, {
    path: "/en/simulacao-seguro-automovel/resultados/:id?/:companyId?/:hash?/results",
    component: _4c576058,
    name: "simulacao-seguro-automovel-resultados-id-companyId-hash-results___en"
  }, {
    path: "/en/simulacao-seguro-automovel/resultados/:id?/:hash?/:plan?/details",
    component: _27e11603,
    name: "simulacao-seguro-automovel-resultados-id-hash-plan-details___en"
  }, {
    path: "/en/simulacao-seguro-vida/resultados/:id?/:companyId/:plan?/details",
    component: _e09cd24e,
    name: "simulacao-seguro-vida-resultados-id-companyId-plan-details___en"
  }, {
    path: "/pt/simulacao-seguro-automovel/obrigado/:id?/:hash?/:plan?/obrigado",
    component: _65e7cdb6,
    name: "simulacao-seguro-automovel-obrigado-id-hash-plan-obrigado___pt"
  }, {
    path: "/pt/simulacao-seguro-automovel/pedir-contacto/:id?/:hash?/:plan?/contact",
    component: _46c7ebc5,
    name: "simulacao-seguro-automovel-pedir-contacto-id-hash-plan-contact___pt"
  }, {
    path: "/pt/simulacao-seguro-automovel/resultados/:id?/:companyId?/:hash?/results",
    component: _4c576058,
    name: "simulacao-seguro-automovel-resultados-id-companyId-hash-results___pt"
  }, {
    path: "/pt/simulacao-seguro-automovel/resultados/:id?/:hash?/:plan?/details",
    component: _27e11603,
    name: "simulacao-seguro-automovel-resultados-id-hash-plan-details___pt"
  }, {
    path: "/pt/simulacao-seguro-vida/resultados/:id?/:companyId/:plan?/details",
    component: _e09cd24e,
    name: "simulacao-seguro-vida-resultados-id-companyId-plan-details___pt"
  }, {
    path: "/en/simulacao-seguro-automovel/resultados/:id?/:companyId?/:hash?/:plan",
    component: _7e166770,
    name: "simulacao-seguro-automovel-resultados-id-companyId-hash-plan___en"
  }, {
    path: "/pt/simulacao-seguro-automovel/resultados/:id?/:companyId?/:hash?/:plan",
    component: _7e166770,
    name: "simulacao-seguro-automovel-resultados-id-companyId-hash-plan___pt"
  }, {
    path: "/en/simulacao-seguro-automovel/resultados/:id?/:companyId?/:hash?/:plan/details",
    component: _45b037a2,
    name: "simulacao-seguro-automovel-resultados-id-companyId-hash-plan-details___en"
  }, {
    path: "/pt/simulacao-seguro-automovel/resultados/:id?/:companyId?/:hash?/:plan/details",
    component: _45b037a2,
    name: "simulacao-seguro-automovel-resultados-id-companyId-hash-plan-details___pt"
  }, {
    path: "/simulacao-seguro-automovel/resultados/:id",
    component: _06ae6105,
    name: "simulacao-seguro-automovel-resultados-id"
  }, {
    path: "/simulacao-seguro-automovel/simulador-novo/:companyId",
    component: _497c6916,
    name: "simulacao-seguro-automovel-simulador-novo-companyId"
  }, {
    path: "/simulacao-seguro-automovel/simulador/:companyId",
    component: _5a7b386a,
    name: "simulacao-seguro-automovel-simulador-companyId"
  }, {
    path: "/simulacao-seguro-multirriscos/resultados/:id",
    component: _29d7883d,
    name: "simulacao-seguro-multirriscos-resultados-id"
  }, {
    path: "/simulacao-seguro-saude/resultados/:id",
    component: _9999176e,
    name: "simulacao-seguro-saude-resultados-id"
  }, {
    path: "/simulacao-seguro-viagem/resultados/:id",
    component: _1935d240,
    name: "simulacao-seguro-viagem-resultados-id"
  }, {
    path: "/simulacao-seguro-vida/resultados/:id",
    component: _210f45d1,
    name: "simulacao-seguro-vida-resultados-id"
  }, {
    path: "/simulacao-seguro-vida/simulador/:companyId",
    component: _61185436,
    name: "simulacao-seguro-vida-simulador-companyId"
  }, {
    path: "/simulacao-seguro-automovel/simulador-novo/:companyId?/simulation",
    component: _723c2e1b,
    name: "simulacao-seguro-automovel-simulador-novo-companyId-simulation"
  }, {
    path: "/simulacao-seguro-automovel/simulador/:companyId?/simulation",
    component: _a747cee0,
    name: "simulacao-seguro-automovel-simulador-companyId-simulation"
  }, {
    path: "/simulacao-seguro-casa/resultados/:id?/results",
    component: _79e94976,
    name: "simulacao-seguro-casa-resultados-id-results"
  }, {
    path: "/simulacao-seguro-multirriscos/resultados/:id?/results",
    component: _758c7264,
    name: "simulacao-seguro-multirriscos-resultados-id-results"
  }, {
    path: "/simulacao-seguro-saude/resultados/:id?/results",
    component: _675b5c42,
    name: "simulacao-seguro-saude-resultados-id-results"
  }, {
    path: "/simulacao-seguro-viagem/resultados/:id?/results",
    component: _2d5e0c8b,
    name: "simulacao-seguro-viagem-resultados-id-results"
  }, {
    path: "/simulacao-seguro-vida/resultados/:id?/results",
    component: _960c888c,
    name: "simulacao-seguro-vida-resultados-id-results"
  }, {
    path: "/simulacao-seguro-vida/simulador/:companyId?/simulador",
    component: _4c61af92,
    name: "simulacao-seguro-vida-simulador-companyId-simulador"
  }, {
    path: "/simulacao-seguro-automovel/resultados/:id?/:hash",
    component: _ade989ba,
    name: "simulacao-seguro-automovel-resultados-id-hash"
  }, {
    path: "/simulacao-seguro-multirriscos/obrigado/:id?/:plan",
    component: _aae0277e,
    name: "simulacao-seguro-multirriscos-obrigado-id-plan"
  }, {
    path: "/simulacao-seguro-multirriscos/pedir-contacto/:id?/:plan",
    component: _abc75f54,
    name: "simulacao-seguro-multirriscos-pedir-contacto-id-plan"
  }, {
    path: "/simulacao-seguro-multirriscos/resultados/:id?/:plan",
    component: _1f738694,
    name: "simulacao-seguro-multirriscos-resultados-id-plan"
  }, {
    path: "/simulacao-seguro-saude/obrigado/:id?/:plan",
    component: _0cbef24d,
    name: "simulacao-seguro-saude-obrigado-id-plan"
  }, {
    path: "/simulacao-seguro-saude/pedir-contacto/:id?/:plan",
    component: _4d399d62,
    name: "simulacao-seguro-saude-pedir-contacto-id-plan"
  }, {
    path: "/simulacao-seguro-saude/resultados/:id?/:plan",
    component: _fa68207c,
    name: "simulacao-seguro-saude-resultados-id-plan"
  }, {
    path: "/simulacao-seguro-viagem/obrigado/:id?/:plan",
    component: _80c11d38,
    name: "simulacao-seguro-viagem-obrigado-id-plan"
  }, {
    path: "/simulacao-seguro-viagem/pedir-contacto/:id?/:plan",
    component: _502e2f39,
    name: "simulacao-seguro-viagem-pedir-contacto-id-plan"
  }, {
    path: "/simulacao-seguro-viagem/resultados/:id?/:plan",
    component: _00edf5ce,
    name: "simulacao-seguro-viagem-resultados-id-plan"
  }, {
    path: "/simulacao-seguro-vida/obrigado/:id?/:plan",
    component: _c4728056,
    name: "simulacao-seguro-vida-obrigado-id-plan"
  }, {
    path: "/simulacao-seguro-vida/pedir-contacto/:id?/:plan",
    component: _34c324ea,
    name: "simulacao-seguro-vida-pedir-contacto-id-plan"
  }, {
    path: "/simulacao-seguro-vida/resultados/:id?/:companyId",
    component: _a2df41e6,
    name: "simulacao-seguro-vida-resultados-id-companyId"
  }, {
    path: "/simulacao-seguro-vida/resultados/:id?/:plan",
    component: _1dd3096c,
    name: "simulacao-seguro-vida-resultados-id-plan"
  }, {
    path: "/simulacao-seguro-automovel/resultados/:id?/:hash?/results",
    component: _2c7c70a8,
    name: "simulacao-seguro-automovel-resultados-id-hash-results"
  }, {
    path: "/simulacao-seguro-multirriscos/obrigado/:id?/:plan/obrigado",
    component: _43a0c302,
    name: "simulacao-seguro-multirriscos-obrigado-id-plan-obrigado"
  }, {
    path: "/simulacao-seguro-multirriscos/pedir-contacto/:id?/:plan/contact",
    component: _77ec316b,
    name: "simulacao-seguro-multirriscos-pedir-contacto-id-plan-contact"
  }, {
    path: "/simulacao-seguro-multirriscos/resultados/:id?/:plan/details",
    component: _9e1a90ae,
    name: "simulacao-seguro-multirriscos-resultados-id-plan-details"
  }, {
    path: "/simulacao-seguro-saude/obrigado/:id?/:plan/obrigado",
    component: _70b5518b,
    name: "simulacao-seguro-saude-obrigado-id-plan-obrigado"
  }, {
    path: "/simulacao-seguro-saude/pedir-contacto/:id?/:plan/contact",
    component: _387885df,
    name: "simulacao-seguro-saude-pedir-contacto-id-plan-contact"
  }, {
    path: "/simulacao-seguro-saude/resultados/:id?/:plan/details",
    component: _6f23661d,
    name: "simulacao-seguro-saude-resultados-id-plan-details"
  }, {
    path: "/simulacao-seguro-viagem/obrigado/:id?/:plan/obrigado",
    component: _251b323c,
    name: "simulacao-seguro-viagem-obrigado-id-plan-obrigado"
  }, {
    path: "/simulacao-seguro-viagem/pedir-contacto/:id?/:plan/contact",
    component: _d7d3a230,
    name: "simulacao-seguro-viagem-pedir-contacto-id-plan-contact"
  }, {
    path: "/simulacao-seguro-viagem/resultados/:id?/:plan/details",
    component: _ebee08b4,
    name: "simulacao-seguro-viagem-resultados-id-plan-details"
  }, {
    path: "/simulacao-seguro-vida/obrigado/:id?/:plan/obrigado",
    component: _420045da,
    name: "simulacao-seguro-vida-obrigado-id-plan-obrigado"
  }, {
    path: "/simulacao-seguro-vida/pedir-contacto/:id?/:plan/contact",
    component: _421fef57,
    name: "simulacao-seguro-vida-pedir-contacto-id-plan-contact"
  }, {
    path: "/simulacao-seguro-vida/resultados/:id?/:companyId/results",
    component: _50c86c04,
    name: "simulacao-seguro-vida-resultados-id-companyId-results"
  }, {
    path: "/simulacao-seguro-vida/resultados/:id?/:plan/details",
    component: _6bab68d6,
    name: "simulacao-seguro-vida-resultados-id-plan-details"
  }, {
    path: "/en/detalhes-companhia/:companyName?/:companyId?/:type",
    component: _61b12ce6,
    name: "detalhes-companhia-companyName-companyId-type___en"
  }, {
    path: "/pt/detalhes-companhia/:companyName?/:companyId?/:type",
    component: _61b12ce6,
    name: "detalhes-companhia-companyName-companyId-type___pt"
  }, {
    path: "/simulacao-seguro-automovel/obrigado/:id?/:hash?/:plan",
    component: _3776d867,
    name: "simulacao-seguro-automovel-obrigado-id-hash-plan"
  }, {
    path: "/simulacao-seguro-automovel/pedir-contacto/:id?/:hash?/:plan",
    component: _4bda3a88,
    name: "simulacao-seguro-automovel-pedir-contacto-id-hash-plan"
  }, {
    path: "/simulacao-seguro-automovel/resultados/:id?/:companyId?/:hash",
    component: _92367312,
    name: "simulacao-seguro-automovel-resultados-id-companyId-hash"
  }, {
    path: "/simulacao-seguro-automovel/resultados/:id?/:hash?/:plan",
    component: _cd74a9c8,
    name: "simulacao-seguro-automovel-resultados-id-hash-plan"
  }, {
    path: "/simulacao-seguro-vida/resultados/:id?/:companyId/:plan",
    component: _7b96b786,
    name: "simulacao-seguro-vida-resultados-id-companyId-plan"
  }, {
    path: "/en/detalhes-companhia/:companyName?/:companyId?/:type?/detalhes-companhia",
    component: _710aa86b,
    name: "detalhes-companhia-companyName-companyId-type-detalhes-companhia___en"
  }, {
    path: "/pt/detalhes-companhia/:companyName?/:companyId?/:type?/detalhes-companhia",
    component: _710aa86b,
    name: "detalhes-companhia-companyName-companyId-type-detalhes-companhia___pt"
  }, {
    path: "/simulacao-seguro-automovel/obrigado/:id?/:hash?/:plan?/obrigado",
    component: _65e7cdb6,
    name: "simulacao-seguro-automovel-obrigado-id-hash-plan-obrigado"
  }, {
    path: "/simulacao-seguro-automovel/pedir-contacto/:id?/:hash?/:plan?/contact",
    component: _46c7ebc5,
    name: "simulacao-seguro-automovel-pedir-contacto-id-hash-plan-contact"
  }, {
    path: "/simulacao-seguro-automovel/resultados/:id?/:companyId?/:hash?/results",
    component: _4c576058,
    name: "simulacao-seguro-automovel-resultados-id-companyId-hash-results"
  }, {
    path: "/simulacao-seguro-automovel/resultados/:id?/:hash?/:plan?/details",
    component: _27e11603,
    name: "simulacao-seguro-automovel-resultados-id-hash-plan-details"
  }, {
    path: "/simulacao-seguro-vida/resultados/:id?/:companyId/:plan?/details",
    component: _e09cd24e,
    name: "simulacao-seguro-vida-resultados-id-companyId-plan-details"
  }, {
    path: "/simulacao-seguro-automovel/resultados/:id?/:companyId?/:hash?/:plan",
    component: _7e166770,
    name: "simulacao-seguro-automovel-resultados-id-companyId-hash-plan"
  }, {
    path: "/simulacao-seguro-automovel/resultados/:id?/:companyId?/:hash?/:plan/details",
    component: _45b037a2,
    name: "simulacao-seguro-automovel-resultados-id-companyId-hash-plan-details"
  }, {
    path: "/detalhes-companhia/:companyName?/:companyId?/:type",
    component: _61b12ce6,
    name: "detalhes-companhia-companyName-companyId-type"
  }, {
    path: "/detalhes-companhia/:companyName?/:companyId?/:type?/detalhes-companhia",
    component: _710aa86b,
    name: "detalhes-companhia-companyName-companyId-type-detalhes-companhia"
  }, {
    path: "/",
    component: _1bcbdb41,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
