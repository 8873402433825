import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_googletagmanager_6d662c55 from 'nuxt_plugin_googletagmanager_6d662c55' // Source: ./google-tag-manager.js (mode: 'client')
import nuxt_plugin_nuxtvuexlocalstorage_196408ba from 'nuxt_plugin_nuxtvuexlocalstorage_196408ba' // Source: ./nuxt-vuex-localstorage.js (mode: 'client')
import nuxt_plugin_workbox_d466d3ce from 'nuxt_plugin_workbox_d466d3ce' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_5edeb42c from 'nuxt_plugin_metaplugin_5edeb42c' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_axios_119a3c3d from 'nuxt_plugin_axios_119a3c3d' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginutils_512e939e from 'nuxt_plugin_pluginutils_512e939e' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_75d8b169 from 'nuxt_plugin_pluginrouting_75d8b169' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_74cedaa6 from 'nuxt_plugin_pluginmain_74cedaa6' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_1b15c1e5 from 'nuxt_plugin_cookieuniversalnuxt_1b15c1e5' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_vuescrollto_049609a8 from 'nuxt_plugin_vuescrollto_049609a8' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_i18n_1fba523a from 'nuxt_plugin_i18n_1fba523a' // Source: ../plugins/i18n.js (mode: 'all')
import nuxt_plugin_axios_5659d192 from 'nuxt_plugin_axios_5659d192' // Source: ../plugins/axios.js (mode: 'all')
import nuxt_plugin_auth_7f7561ce from 'nuxt_plugin_auth_7f7561ce' // Source: ../plugins/auth.js (mode: 'all')
import nuxt_plugin_metas_4f6a5cc2 from 'nuxt_plugin_metas_4f6a5cc2' // Source: ../plugins/metas.js (mode: 'all')
import nuxt_plugin_lazysizes_84331be2 from 'nuxt_plugin_lazysizes_84331be2' // Source: ../plugins/lazysizes.js (mode: 'all')
import nuxt_plugin_swiper_68e7f06e from 'nuxt_plugin_swiper_68e7f06e' // Source: ../plugins/swiper.js (mode: 'all')
import nuxt_plugin_vuelidate_4be431c8 from 'nuxt_plugin_vuelidate_4be431c8' // Source: ../plugins/vuelidate.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"script":[{"src":"https:\u002F\u002Fpolyfill.io\u002Fv3\u002Fpolyfill.min.js?features=Array.from","body":true},{"src":"\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id=GTM-W87575M&l=dataLayer","async":true}],"meta":[{"hid":"charset","charset":"utf-8"},{"hid":"viewport","name":"viewport","content":"width=device-width, initial-scale=1"},{"rel":"preconnect","href":"https:\u002F\u002Ffullstory.com","crossorigin":true},{"rel":"preconnect","href":"https:\u002F\u002Fwww.googletagmanager.com","crossorigin":true},{"rel":"preconnect","href":"https:\u002F\u002Fwww.google-analytics.com","crossorigin":true},{"rel":"preconnect","href":"https:\u002F\u002Fstats.g.doubleclick.net","crossorigin":true},{"rel":"preconnect","href":"https:\u002F\u002Fpolicy.app.cookieinformation.com\u002Fuc.js","crossorigin":true},{"rel":"preconnect","href":"https:\u002F\u002Fwww.google.com\u002F","crossorigin":true}],"link":[],"style":[],"noscript":[{"hid":"gtm-noscript","innerHTML":"\u003Ciframe src=\"\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-W87575M&l=dataLayer\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"\u003E\u003C\u002Fiframe\u003E","pbody":true}],"__dangerouslyDisableSanitizersByTagID":{"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.client && typeof nuxt_plugin_googletagmanager_6d662c55 === 'function') {
    await nuxt_plugin_googletagmanager_6d662c55(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtvuexlocalstorage_196408ba === 'function') {
    await nuxt_plugin_nuxtvuexlocalstorage_196408ba(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_d466d3ce === 'function') {
    await nuxt_plugin_workbox_d466d3ce(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_5edeb42c === 'function') {
    await nuxt_plugin_metaplugin_5edeb42c(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_119a3c3d === 'function') {
    await nuxt_plugin_axios_119a3c3d(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_512e939e === 'function') {
    await nuxt_plugin_pluginutils_512e939e(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_75d8b169 === 'function') {
    await nuxt_plugin_pluginrouting_75d8b169(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_74cedaa6 === 'function') {
    await nuxt_plugin_pluginmain_74cedaa6(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_1b15c1e5 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_1b15c1e5(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_049609a8 === 'function') {
    await nuxt_plugin_vuescrollto_049609a8(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_1fba523a === 'function') {
    await nuxt_plugin_i18n_1fba523a(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5659d192 === 'function') {
    await nuxt_plugin_axios_5659d192(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_7f7561ce === 'function') {
    await nuxt_plugin_auth_7f7561ce(app.context, inject)
  }

  if (typeof nuxt_plugin_metas_4f6a5cc2 === 'function') {
    await nuxt_plugin_metas_4f6a5cc2(app.context, inject)
  }

  if (typeof nuxt_plugin_lazysizes_84331be2 === 'function') {
    await nuxt_plugin_lazysizes_84331be2(app.context, inject)
  }

  if (typeof nuxt_plugin_swiper_68e7f06e === 'function') {
    await nuxt_plugin_swiper_68e7f06e(app.context, inject)
  }

  if (typeof nuxt_plugin_vuelidate_4be431c8 === 'function') {
    await nuxt_plugin_vuelidate_4be431c8(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
