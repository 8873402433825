export const capitalizeSnakeCase = (string) => {
  const name = string.split('_')
  const capitalizedFirstString =
    name[0].charAt(0).toUpperCase() + name[0].slice(1)
  name.shift()
  name.unshift(capitalizedFirstString)

  return name.join(' ')
}

export const toCamelCase = (string) => {
  string = string
    .toLowerCase()
    .replace(/(?:(^.)|([-_\s]+.))/g, function(match) {
      return match.charAt(match.length - 1).toUpperCase()
    })
  return string.charAt(0).toLowerCase() + string.substring(1)
}
