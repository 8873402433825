import { camelizeKeys } from '~/utils/objects'

export const state = () => ({
  isLoggedIn: false,
  accessToken: null,
  user: null
})

export const mutations = {
  SET_ACCESS_TOKEN(state, token) {
    state.accessToken = token
    state.isLoggedIn = true
  },

  SET_USER_DATA(state, user) {
    state.user = camelizeKeys(user)
  },

  REMOVE_ACCESS_TOKEN(state) {
    state.accessToken = null
    state.isLoggedIn = false
  }
}

export const actions = {
  getUserData({ commit }) {
    return this.$axios
      .get('/user/info')
      .then(({ data }) => {
        commit('SET_USER_DATA', data)
      })
      .catch((_error) => {
        commit('REMOVE_ACCESS_TOKEN')
      })
  }
}
