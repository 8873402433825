import { toCamelCase } from '~/utils/strings'

export const camelizeKeys = (obj) => {
  return Object.keys(obj).reduce(
    (accumulator, field) => ({
      ...accumulator,
      [toCamelCase(field)]: obj[field]
    }),
    {}
  )
}
