export const actions = {
  turnLeadHot(
    { commit },
    { requestID, productID, planID, hash, name, email, phoneNumber, message }
  ) {
    this.$axios.put('/leads/update-lead', {
      requestID,
      productID,
      planID,
      hash,
      email,
      name,
      phoneNumber,
      message
    })
  },

  createLead({ commit }, { requestID, productID, planID, leadType }) {
    this.$axios.post('/leads/create-lead', {
      requestID,
      productID,
      planID,
      lead_type: leadType
    })
  }
}
