export const convertCurrencyToFloat = (currency) => {
  if (!isNaN(currency)) {
    return currency
  }

  return parseFloat(
    currency
      .replace('€', '')
      .replace('.', '')
      .replace(',', '.')
  )
}
