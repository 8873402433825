import isNil from 'lodash.isnil'

export default async function({ app, store }) {
  const accessToken = app.$cookies.get('access_token')

  if (isNil(accessToken)) {
    return
  }

  store.commit('auth/SET_ACCESS_TOKEN', accessToken)
  await store.dispatch('auth/getUserData')
}
