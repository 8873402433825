

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en","file":"en-GB.js","iso":"en-GB","name":"English"},{"code":"pt","file":"pt-PT.js","iso":"pt-PT","name":"Português"}],
  defaultLocale: "pt",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/home/runner/work/website/website/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"user_language","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":true,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","file":"en-GB.js","iso":"en-GB","name":"English"},{"code":"pt","file":"pt-PT.js","iso":"pt-PT","name":"Português"}],
  localeCodes: ["en","pt"],
}

export const localeMessages = {
  'en-GB.js': () => import('../../lang/en-GB.js' /* webpackChunkName: "lang-en-GB.js" */),
  'pt-PT.js': () => import('../../lang/pt-PT.js' /* webpackChunkName: "lang-pt-PT.js" */),
}
