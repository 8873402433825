export const state = () => ({
  simulations: {
    carInsurance: [],
    lifeInsurance: [],
    homeInsurance: [],
    travelInsurance: [],
    healthInsurance: []
  },
  simulationData: {}
})

export const mutations = {
  ADD_ANONYMOUS_CAR_SIMULATION(state, payload) {
    state.simulations.carInsurance.push({
      id: payload.id,
      hash: payload.hash,
      ts: new Date()
    })
  },
  ADD_ANONYMOUS_LIFE_SIMULATION(state, payload) {
    state.simulations.lifeInsurance.push({
      id: payload.uuid,
      ts: new Date()
    })
  },
  ADD_ANONYMOUS_HOME_SIMULATION(state, payload) {
    state.simulations.homeInsurance.push({
      id: payload.id,
      ts: new Date()
    })
  },
  ADD_ANONYMOUS_TRAVEL_SIMULATION(state, payload) {
    state.simulations.travelInsurance.push({
      id: payload.id,
      ts: new Date()
    })
  },
  ADD_ANONYMOUS_HEALTH_SIMULATION(state, payload) {
    state.simulations.healthInsurance.push({
      id: payload.id,
      ts: new Date()
    })
  },
  SAVE_SIMULATION_DATA(state, payload) {
    state.simulationData = payload
  }
}
