const isDev = process.env.ENV !== 'production'

export default ({ app, store, route }) => {
  const fullPath = route.fullPath.replace(/\/(pt|en)/, '')
  const path = fullPath !== '' ? fullPath.split('?')[0] : '/'
  const locale = route.fullPath.split('/')[1]

  return app.$axios
    .$get(`/page?route=${path}&language=${locale}`)
    .then((response) => {
      // eslint-disable-next-line no-unused-vars
      const itemsToReplace = [
        {
          hid: 'description',
          name: 'description',
          content: response.meta_description
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: response.meta_keywords
        },
        {
          hid: 'robots',
          name: 'robots',
          content: isDev ? 'noindex,nofollow' : response.robots
        }
      ]

      itemsToReplace.forEach((item) => {
        const metaIndex = app.head.meta.findIndex(
          (meta) => meta.hid === item.hid
        )

        if (metaIndex === -1) {
          app.head.meta.push(item)
          return
        }

        app.head.meta[metaIndex] = item
      })

      app.head.title = response.head_title
      store.commit('SET_META', response)
    })
}
