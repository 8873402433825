import Vue from 'vue'
import { convertCurrencyToFloat } from '~/utils/currency'

export const state = () => ({
  quotes: []
})

export const mutations = {
  SET_QUOTES(state, payload) {
    state.quotes = payload
  },
  UPDATE_QUOTE(state, payload) {
    payload.forEach((response) => {
      const planId = response.plan.id
      const planIndex = state.quotes
        .map((quote) => quote.plan.id)
        .indexOf(planId)

      if (planIndex !== -1) {
        Vue.set(state.quotes[planIndex], 'premiums', response.premiums)
        Vue.set(state.quotes[planIndex], 'status', response.status)
      } else {
        state.quotes.push(response)
      }
    })
  },
  SET_QUOTE(state, payload) {
    state.quotes.push(payload)
  }
}

export const getters = {
  companies: (state) => {
    return state.quotes.reduce((accumulator, quote) => {
      const companyExistOnArray = accumulator.find(
        (company) => company.id === quote.company.id
      )

      if (companyExistOnArray) {
        return accumulator
      }

      return accumulator.concat([quote.company])
    }, [])
  },
  coverages: (state) => {
    return state.quotes.reduce((accumulator, quote) => {
      quote.coverages.forEach((coverage) => {
        const coverageName = coverage.name

        if (accumulator.includes(coverageName)) {
          return accumulator
        }

        accumulator.push(coverageName)
      })

      return accumulator
    }, [])
  },
  maximiumPremiums: (state) => {
    const premiums = {
      annual_premium: 0,
      halfyear_premium: 0,
      quarterly_premium: 0,
      monthly_premium: 0
    }

    state.quotes.forEach((quote) => {
      const quotePremiums = quote.premiums

      Object.keys(premiums).forEach((key) => {
        const actualPremium = convertCurrencyToFloat(premiums[key])

        if (quotePremiums[key] === null || quotePremiums[key] === undefined) {
          return
        }

        const premium = convertCurrencyToFloat(quotePremiums[key])

        if (actualPremium > premium) {
          return
        }

        premiums[key] = premium
      })
    })

    return premiums
  }
}

export const actions = {
  async getPlan({ commit }, { id, hash, planId }) {
    const response = await this.$axios.get(
      `/carinsurance/results/${id}/${hash}/${planId}`
    )

    commit('SET_QUOTE', response.data.message)
  }
}
