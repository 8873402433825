import https from 'https'

/* eslint-disable no-console */
export default function({ $axios, store }) {
  $axios.defaults.baseURL = process.env.CLIENT_API_URL

  if (process.server) {
    $axios.defaults.baseURL = process.env.SERVER_API_URL
  }

  $axios.onRequest((config) => {
    config.headers.common['Content-Type'] = 'application/json'
    config.headers.common['x-broker-token'] = process.env.API_TOKEN

    if (process.server) {
      config.httpsAgent = new https.Agent({
        rejectUnauthorized: false
      })
    }

    if (store.state.auth.isLoggedIn) {
      config.headers.common.Authorization = `Bearer ${store.state.auth.accessToken}`
    }
  })

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)

    /// / Should see what we do when token expired but the route is public
    if (code === 400) {
      // eslint-disable-next-line no-throw-literal
      throw { statusCode: 401, message: 'Unathorized' }
    }
  })
}
