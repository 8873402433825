import Vue from 'vue'
import { convertCurrencyToFloat } from '~/utils/currency'

export const state = () => ({
  currentPremium: 0,
  quotes: []
})

export const mutations = {
  SET_QUOTES(state, payload) {
    let aux
    payload.forEach((element) => {
      if (element.has_premiums) {
        if (element.premiums.premium !== null) {
          aux = element.premiums.premium
          element.premiums.premium = aux
        }
      }
    })
    state.quotes = payload
  },
  SET_CURRENT_PREMIUM(state, payload) {
    state.currentPremium = payload
  },
  UPDATE_QUOTE(state, payload) {
    let aux
    if (payload.has_premiums) {
      if (payload.premiums.premium !== null) {
        aux = payload.premiums.premium
        payload.premiums.premium = aux
      }
    }
    const planId = payload.plan.id
    const planIndex = state.quotes.map((quote) => quote.plan.id).indexOf(planId)

    Vue.set(state.quotes[planIndex], 'premiums', payload.premiums)
    Vue.set(state.quotes[planIndex], 'status', payload.status)
  },
  SET_QUOTE(state, payload) {
    state.quotes.push(payload)
  }
}

export const getters = {
  companies: (state) => {
    return state.quotes.reduce((accumulator, quote) => {
      const companyExistOnArray = accumulator.find(
        (company) => company.id === quote.company.id
      )

      if (companyExistOnArray) {
        return accumulator
      }

      return accumulator.concat([quote.company])
    }, [])
  },
  coverages: (state) => {
    return state.quotes.reduce((accumulator, quote) => {
      quote.coverages.forEach((coverage) => {
        const coverageName = coverage.name

        if (accumulator.includes(coverageName)) {
          return accumulator
        }
        accumulator.push(coverageName)
      })

      return accumulator
    }, [])
  },
  maximiumPremiums: (state) => {
    const premiums = {
      premium: 0
    }
    state.quotes.forEach((quote) => {
      const quotePremiums = quote.premiums

      Object.keys(premiums).forEach((key) => {
        const actualPremium = convertCurrencyToFloat(premiums[key])

        if (quotePremiums[key] === null || quotePremiums[key] === undefined) {
          return
        }

        const premium = quotePremiums[key]

        if (actualPremium > convertCurrencyToFloat(premium)) {
          return
        }

        premiums[key] = convertCurrencyToFloat(premium)
      })
    })

    return premiums
  }
}

export const actions = {
  async getPlan({ commit }, { id, planId }) {
    const response = await this.$axios.get(
      `/travelinsurance/results/${id}/${planId}`
    )
    commit('SET_QUOTE', response.data)
  }
}
