export const strict = false
export const state = () => ({
  isCookiesAcepted: false,
  page: {
    head: ''
  }
})

export const mutations = {
  SET_META(state, payload) {
    state.page = payload
  },
  SET_COOKIE_ACCEPTED(state) {
    state.isCookiesAcepted = true
  }
}
