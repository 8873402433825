const shuffle = require('lodash.shuffle')

export const state = () => ({
  members: []
})

export const mutations = {
  SET_TEAM(state, payload) {
    state.members = shuffle(payload)
  }
}

export const actions = {
  async getTeam({ commit }) {
    const response = await this.$axios.get('/team')
    commit('SET_TEAM', response.data)
  }
}
